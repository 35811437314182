import Auth from '../../services/helpers/amplify';
import AppEventEmitter from '../AppEventEmitter';
import RpcApiError from './rpcApiError';

export default class ApiClient {
  constructor(http) {
    this.http = http;
    http.initErrorResponseInterceptor(async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 419) {
        await Auth.signOut();
        this.setToken('');
        window.location.href = '/';
        return;
      }
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: await this.refreshTokens(),
          };

          return http.repeatRequest(originalRequest);
        } catch (e) {
          localStorage.clear();
          AppEventEmitter.instance.emit('unauthorized');
        }
      } else {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          AppEventEmitter.instance.emit('unauthorized');
        }
      }
      return Promise.reject(error);
    });
  }

  setToken(token) {
    this.http.setAuthorizationToken(token);
  }

  async refreshTokens() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    const session = await new Promise((resolve, reject) =>
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) reject(err);
        resolve(session);
      })
    );
    const { accessToken } = session;
    this.setToken(accessToken.jwtToken);
    return accessToken.jwtToken;
  }

  getHealthCheck() {
    return this.http.get('/');
  }

  createDocument(formData) {
    return this.http.post('/document', formData);
  }

  updateDocument(formData) {
    return this.http.put('/document', formData);
  }

  exportDocument(ids) {
    return this.http.get('/csv/documents/export', { ids });
  }

  exportBuildingMaterials(ids) {
    return this.http.get('/csv/materials/export', { ids });
  }

  exportFixtures(ids) {
    return this.http.get('/csv/fixtures/export', { ids });
  }

  exportReport(formData, type = 'material') {
    return this.http.post(
      type === 'pieceworks'
        ? '/csv/pieceworks/export'
        : type === 'material'
          ? '/csv/materials/export'
          : '/csv/fixtures/export',
      formData
    );
  }

  importBuildingMaterials(formData) {
    return this.http.post('/csv/materials/import', formData);
  }

  importFixtures(formData) {
    return this.http.post('/csv/fixtures/import', formData);
  }

  importDocumentsCsv(formData) {
    return this.http.post('/csv/documents/import', formData);
  }

  getBuildingMaterialsImportProgress(hash) {
    return this.http.get(`/csv/materials/progress/${hash}`);
  }

  getFixturesImportProgress(hash) {
    return this.http.get(`/csv/fixtures/progress/${hash}`);
  }

  uploadFile(originFile, method, params) {
    const {taskId, projectId, nameReplace } = params;    
    const dataForm = {taskId, projectId, name:nameReplace}
    const formData = new FormData();
    formData.set('method', method);
    formData.set('params', JSON.stringify(dataForm));
    if (Array.isArray(originFile)) originFile.forEach((file) => formData.append('file', file));
    else formData.set('file', originFile);


    return this.http.post('/upload', formData);

  }

  async jsonRpc(data) {
    try {
      let result = await this.http.post('/', data);
      if (result && result.error && result.error.code < 0) {
        return Promise.reject(new RpcApiError(result.error));
      }
      return Array.isArray(result) ? result : (result && result.result) || {};
    } catch (e) {
      return Promise.reject(new RpcApiError(e));
    }
  }
}
